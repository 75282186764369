import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import Animated from 'react-native-reanimated';

const AnimatedPath = Animated.createAnimatedComponent(Path);

interface Props extends SvgProps {
    animatedProps?: any;
}

const IcBackButton = ({animatedProps, ...rest}: Props) => (
    <Svg
        width={10}
        height={18}
        {...rest}
    >
        <AnimatedPath
            animatedProps={animatedProps}
            d="M9.725 2.225L8.25.75 0 9l8.25 8.25 1.475-1.475L2.95 9l6.775-6.775z"
        />
    </Svg>
);

export default IcBackButton;

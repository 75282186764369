import * as ImagePicker from 'expo-image-picker';
import useCrouton from './useCrouton';
import {useTranslation} from 'react-i18next';

const MAX_FILE_SIZE = 1024 * 1024 * 9;

const useCheckFileSize = (maxFileSize = MAX_FILE_SIZE) => {
    const {t} = useTranslation();
    const crouton = useCrouton();

    const checkFileSize = (imagePickerResult?: ImagePicker.ImagePickerAsset) => {
        if(imagePickerResult && imagePickerResult.fileSize! > maxFileSize) {
            crouton.show({
                title: t('actions.warning'),
                message: t('auth.avatar.fileSizeError'),
                preset: 'error',
            });
            return false;
        }

        return true;
    };

    return {checkFileSize};
};

export default useCheckFileSize;

import {Gender, MaleFemaleGender, User, UserUiData, UserUiDataWithEmail} from '@shared/models/user';
import {get} from 'lodash';

const userUiData = (user: User | UserUiData): UserUiData => {
    const {uid, id, username, avatar, gender} = user;
    const hcp = getHcp(user);
    return {uid, id, username, ...(avatar && {avatar}), ...(hcp !== undefined && {hcp}), ...(gender && {gender})};
};

const userUiDataWithEmail = (user: User | UserUiDataWithEmail): UserUiDataWithEmail => {
    const {email} = user;
    return {...userUiData(user), email};
};

const genderToMaleFemaleGender = (gender?: Gender): MaleFemaleGender => {
    return gender === undefined || gender === 'other' ? 'male' : gender;
};

const getUserClub = (user: User) => user.sports?.golf?.club;

const getHcp = (user: User | UserUiData) => {
    // Check if user is a UserUiData
    if ('hcp' in user) {
        return user.hcp;
    }

    // Check if user is a User
    if('sports' in user) {
        return user.sports?.golf?.hcp;
    }
};

const usernameToInitials = (name?: string) => {
    if(!name) {
        return '';
    }
    return name.split(' ').map(s => s[0]).join('').slice(0, 2);
};

const usernameToInitialsArray = (name?: string, length?: number) => {
    return name ? name.split(' ').map(s => s[0]?.toUpperCase()).slice(0, length ?? 2) : [];
};

const getAvatarSize = (size: string) => {
    switch(size) {
        case 'xs':
            return '18px';
        case 'sm':
            return '40px';
        case 'md':
            return 50;
        case 'lg':
            return 70;
        case 'xl':
            return 100;
        case '2xl':
            return 160;
        default:
            return 50;
    }
};

const getMissingAvatarFontSize = (size: string) => {
    switch(size) {
        case 'sm':
            return 16;
        case 'md':
            return 24;
        case 'lg':
            return 32;
        case 'xl':
            return 48;
        case '2xl':
            return 72;
        default:
            return 24;
    }
};

const colors = ['red', 'green', 'blue', 'yellow', 'purple', 'pink', 'orange', 'cyan', 'teal', 'lime', 'gray', 'emerald', 'indigo', 'fuchsia', 'violet', 'rose', 'green'];

const randomColorFromString = (string?: string) => {
    if(!string) {
        return colors[Math.floor(Math.random() * colors.length)];
    }
    const index = string.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % colors.length;
    return colors[index];
};

const usernameToInitialsWithNewLines = (name: string, length: number) => {
    const chunks = name.split(' ');
    if(chunks.length <= length) {
        return chunks.join(' ');
    }
    // slice half
    const half = Math.ceil(chunks.length / length);
    const firstHalf = chunks.slice(0, half);
    const secondHalf = chunks.slice(half);
    const firstHalfString = firstHalf.join(' ');
    const secondHalfString = secondHalf.join(' ');
    return `${firstHalfString}\n${secondHalfString}`;
};

const isProfileCompleted = (user?: User) => {
    if(!user) {
        return false;
    }

    const requiredFields = ['firstName', 'lastName', 'username', 'email', 'birthday', 'gender', 'phone', 'sports.golf.hcp'];

    for (let i = 0; i < requiredFields.length; i++) {
        const field = requiredFields[i];

        if (get(user, field) === undefined || get(user, field) === null || get(user, field) === '') {
            return false;
        }
    }
    return true;
};

export {
    userUiData,
    userUiDataWithEmail,
    getUserClub,
    usernameToInitials,
    usernameToInitialsArray,
    usernameToInitialsWithNewLines,
    getAvatarSize,
    getMissingAvatarFontSize,
    randomColorFromString,
    isProfileCompleted,
    genderToMaleFemaleGender,
};

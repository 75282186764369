import * as React from 'react';
import Svg, {
    Circle,
    ClipPath,
    Defs,
    G,
    Path,
    SvgProps,
} from 'react-native-svg';

interface IcCircleCrossProps extends SvgProps {
    border?: string;
}

const IcCircleCross = (props: IcCircleCrossProps) => (
    <Svg
        width={props.width ?? 25}
        height={props.height ?? 25}
        fill="none"
        viewBox="0 0 25 25"
    >
        <Circle
            cx={12.5}
            cy={12.5}
            r={11.5}
            fill={props.fill ?? '#151515'}
            stroke={props.border ?? props.stroke ?? '#fff'}
            strokeWidth={props.strokeWidth ?? 2}
        />

        <G clipPath="url(#ic_close)">
            <Path
                d="m17.056 8.861-.918-.918-3.64 3.64-3.639-3.64-.918.918 3.64 3.64-3.64 3.639.918.918 3.64-3.64 3.639 3.64.918-.918-3.64-3.64 3.64-3.639Z"
                fill={props.stroke ?? '#fff'}
            />
        </G>

        <Defs>
            <ClipPath id="ic_close">
                <Path
                    fill={props.stroke ?? '#fff'}
                    transform="translate(4.688 4.688)"
                    d="M0 0h15.625v15.625H0z"
                />
            </ClipPath>
        </Defs>
    </Svg>
);

export default IcCircleCross;

import React, {useEffect} from 'react';
import {initAppCheck, initFirebase} from '@ui/helpers/firebase';
import AnalyticsTrackingEventsProvider from './analytics/AnalyticsTrackingEventsProvider';
import {AppConfigProvider} from './app/AppConfigProvider';
import {
    BottomSheetModalProvider,
} from '@gorhom/bottom-sheet';
import {CompetitionProvider} from '@ui/provider/competition/CompetitionProvider';
import {CreateTeamProvider} from '@ui/provider/CreateTeamProvider';
import {EntityProvider} from './entity/EntityProvider';
import {LinearGradient} from 'expo-linear-gradient';
import {NativeBaseProvider} from 'native-base';
import {NotificationProvider} from '@ui/provider/notification/NotificationProvider';
import OnboardingProvider from './onboarding/OnboardingProvider';
import {Platform} from 'react-native';
import {SolitoImageProvider} from 'solito/image';
import {StripeProvider} from '@ui/provider/stripe';
import Toast from 'react-native-toast-message';
import {UserProvider} from '@ui/provider/user/UserProvider';
import {UserRegistrationProvider} from '@ui/provider/registration/UserRegistrationProvider';
import {WalletProvider} from '@ui/provider/wallet';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import {initI8n} from '@ui/helpers/i18n';
import nativeBaseTheme from '@ui/helpers/native-base';
import {toastConfig} from './toast/toastConfig';

countries.registerLocale(enLocale);

initFirebase();

initI8n();

const Provider = ({children}: { children: React.ReactElement }) => {

    const config = {
        dependencies: {
            'linear-gradient': LinearGradient,
        },
    };


    useEffect(() => {
        initAppCheck();
    }, []);

    return (
        <NativeBaseProvider config={config} theme={nativeBaseTheme} isSSR={Platform.OS === 'web'}>
            <UserProvider>
                <NotificationProvider>
                    <UserRegistrationProvider>
                        <CompetitionProvider>
                            <EntityProvider>
                                <CreateTeamProvider>
                                    <WalletProvider>
                                        <StripeProvider>
                                            <SolitoImageProvider
                                                nextJsURL="https://picsum.photos"
                                            >

                                                <BottomSheetModalProvider>
                                                    <OnboardingProvider>
                                                        <AnalyticsTrackingEventsProvider>
                                                            <AppConfigProvider>
                                                                {children}
                                                            </AppConfigProvider>
                                                        </AnalyticsTrackingEventsProvider>
                                                    </OnboardingProvider>
                                                </BottomSheetModalProvider>

                                                <Toast config={toastConfig} />
                                            </SolitoImageProvider>
                                        </StripeProvider>
                                    </WalletProvider>
                                </CreateTeamProvider>
                            </EntityProvider>
                        </CompetitionProvider>
                    </UserRegistrationProvider>
                </NotificationProvider>
            </UserProvider>
        </NativeBaseProvider>
    );
};

export {Provider};

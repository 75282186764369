import {query, where} from 'firebase/firestore';
import {RegistrationStatus} from '@shared/models/registration';
import {getCollections} from '@ui/helpers/firebase.web';
import {useCollectionData} from 'react-firebase-hooks/firestore';

const registrationQuery = (uid: string) => query(getCollections().registrations,
    where('uids', 'array-contains', uid),
    where('status', '==', RegistrationStatus.Registered),
);

const useUserRegistrations = (uid?: string) => useCollectionData(uid ?
    registrationQuery(uid) :
    undefined,
);


export {useUserRegistrations};

import {FirestoreDataConverter} from 'firebase/firestore';
import {Fixture} from '@shared/models/fixture';

const FixtureConverter: FirestoreDataConverter<Fixture> = {
    fromFirestore: (snapshot) => {
        const data = snapshot.data();
        return {
            ...data,
            startsAt: data.startsAt.toDate(),
            endsAt: data.endsAt.toDate(),
        } as Fixture;
    },
    toFirestore: (modelObject) => {
        return modelObject;
    },
};

export {FixtureConverter};

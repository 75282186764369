import AsyncStorage from '@react-native-async-storage/async-storage';
import React from 'react';

const KEY = 'isFirstAppStartUp';

export const useIsFirstAppStartUp = () => {
    const [isFirstAppStartUp, setIsFirstAppStartUp] = React.useState<boolean>(true);

    React.useEffect(() => {
        AsyncStorage.getItem(KEY).then(value => {
            setIsFirstAppStartUp(value === null);
        });
    }, []);

    const setFirstAppStartUp = React.useCallback(async() => {
        await AsyncStorage.setItem(KEY, 'false');
        setIsFirstAppStartUp(false);
    }, []);

    return {isFirstAppStartUp, setFirstAppStartUp};
};

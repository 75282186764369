import React, {useContext, useMemo, useState} from 'react';
import {User, UserCreateRequest, UserCreateResponse} from '@shared/models/user';
import {checkRedirectResult, createUserWithEmailAndPassword, logInAnonymously, reauthenticateUser, sendPasswordResetEmail, setPersistence, signInWithApple, signInWithEmailAndPassword, signInWithGoogle, signOut, updatePassword, useAuthState} from '@ui/helpers/firebase/auth';
import {get, isNil} from '@shared/helpers/lang';
import {getIdTokenResultClaims, onACLChanged, updateUser} from './user-fn';
import {isEqual, isString} from 'lodash';
import {useUserDoc, userExists} from './user-hooks';
import {ACL} from '@shared/models/acl';
import {ClubUiData} from '@shared/models/club';
import {FirestoreTimestamp} from '@shared/models/firestore-timestamp';
import {SocialLoginResult} from './types';
import {WalletEventType} from '@shared/models/wallet';
import {convertAuthErrorKey} from '@ui/features/auth/helpers/auth-errors';
import {createContext} from 'react';
import {deleteFieldValue} from '@ui/helpers/firebase/firestore';
import {isProfileCompleted} from '@shared/helpers/user-fn';
import {track} from '@ui/helpers/analytics/analytics';
import useCallable from '@ui/hooks/useCallable';
import useCrouton from '@ui/hooks/useCrouton';
import {useEffect} from 'react';
import useLogWalletEvent from '@ui/features/events/hooks/useLogWalletEvent';
import {useTranslation} from 'react-i18next';

type UserContextValue = ReturnType<typeof useProvideUser>;

const UserContext = createContext<ReturnType<typeof useProvideUser> | undefined>(undefined);

export declare class FirebaseError extends Error {
    /** The error code for this error. */
    readonly code: string;
    /** Custom data for this error. */
    customData?: Record<string, unknown> | undefined;
    /** The custom name for all FirebaseErrors. */
    readonly name: string;
    constructor(
        /** The error code for this error. */
        code: string, message: string,
        /** Custom data for this error. */
        customData?: Record<string, unknown> | undefined);
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useProvider().
export const UserProvider = ({
    children,
    value,
}: {
    children: React.ReactElement
    value?: UserContextValue
}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = value ?? useProvideUser();
    return (
        <UserContext.Provider value={user}>{children}</UserContext.Provider>
    );
};

// Hook for child components to get the user object ...
// ... and re-render when it changes.
export const useUser = () => {
    return useContext(UserContext)!;
};

// Provider hook that creates user object and handles state
const useProvideUser = () => {
    const [firebaseUser, firebaseLoading, firebaseUserError] = useAuthState();
    const [userUid, setUserUid] = useState<string>();
    const [user, isUserHookLoading, userError] = useUserDoc(userUid);

    const [actionError, setActionError] = useState<unknown | undefined>(undefined);
    const [createUser, isCreating] = useCallable<UserCreateRequest, UserCreateResponse>('httpsUserCreate-default');
    const [deleteUser, isDeleteUserLoading] = useCallable('user-deleteAccount');
    const {onLogEvent} = useLogWalletEvent();

    const [isLogInLoading, setLogInLoading] = useState(false);
    const [isLogOutLoading, setLogOutLoading] = useState(false);
    const [isRegisterLoading, setRegisterLoading] = useState(false);
    const [isLogInWithGoogleLoading, setIsLogInWithGoogleLoading] = useState(false);
    const [isLogInWithAppleLoading, setIsLogInWithAppleLoading] = useState(false);
    const [isResetPasswordLoading, setResetPasswordLoading] = useState(false);
    const [isUpdateUserLoading, setIsUpdateUserLoading] = useState(false);
    const [isUpdatePasswordLoading, setIsUpdatePasswordLoading] = useState(false);
    const [isAnonymousLoading, setIsAnonymousLoading] = useState(false);
    const [isTournamentOrganizer, setIsTournamentOrganizer] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [hasLoggedEvent, setHasLoggedEvent] = useState(false);

    const [aclLastUpdated, setAclLastUpdated] = useState<Date | FirestoreTimestamp>();

    const isUserLoading = useMemo(() => !isNil(user) ? false : isUserHookLoading || (!isNil(firebaseUser) && !firebaseUser!.isAnonymous && isNil(userError)), [firebaseUser, isUserHookLoading, user, userError]);
    const isLoggedIn = useMemo(() => !isNil(firebaseUser) && !isNil(user), [firebaseUser, user]);
    const isLoading = useMemo(() => firebaseLoading || isUserLoading, [firebaseLoading, isUserLoading]);
    const isError = useMemo(() => !isNil(firebaseUserError) || !isNil(userError), [firebaseUserError, userError]);
    const isSocialLogin = useMemo(() => !isNil(firebaseUser) && firebaseUser?.providerData[0]?.providerId !== 'password', [firebaseUser]);

    const crouton = useCrouton();
    const {t} = useTranslation();

    useEffect(() => {
        if(actionError) {
            crouton.show({
                title: t('actions.error'),
                message: convertAuthErrorKey(t, actionError),
                preset: 'error',
            });

            setActionError(undefined);
        }
    }, [actionError]);

    useEffect(() => {
        if(user){
            checkRoles(false);
            onACLChanged(user.uid, (data) => {
                onNewClaims(data);
            });
            if(!hasLoggedEvent){
                onLogEvent(WalletEventType.LOG_IN);
                setHasLoggedEvent(true);
                updateUser(user.uid, {lastLogin: new Date()});
            }
        } else {
            setAclLastUpdated(undefined);
        }
    }, [user]);

    useEffect(() => {
        (async() => {
            const result = await checkRedirectResult();
            if(result){
                await setSocialUser(result);
            }
        })();
    }, []);

    const checkRoles = async(refresh: boolean) => {
        const result = await getIdTokenResultClaims(refresh);
        setIsTournamentOrganizer(result?.tournamentOrganizer ?? false);
        setIsAdmin(result?.admin ?? false);
    };

    const onNewClaims = async(acl: ACL) => {
        if (acl.lastUpdated) {
            if (aclLastUpdated && !isEqual(acl.lastUpdated, aclLastUpdated)) {
            // Force a refresh of the user's ID token
                checkRoles(true);
            }
            setAclLastUpdated(acl.lastUpdated);
        }
    };

    useEffect(() => {
        if(!isLogInLoading && !isRegisterLoading && !isLogInWithGoogleLoading && !isLogInWithAppleLoading && firebaseUser && !firebaseUser.isAnonymous) {
            setUserUid(firebaseUser?.uid);
        }
    }, [firebaseUser]);

    const setSocialUser = async(result: SocialLoginResult) => {
        const {uid} = result;

        const isNewUser = result.isNewUser ? true : !(await userExists(uid));

        if(isNewUser) {
            await createUser({
                firstName: result.firstName ?? t('auth.defaultUser.firstName'),
                lastName: result.lastName ?? t('auth.defaultUser.lastName'),
                username: result.username ?? t('auth.defaultUser.username'),
                email: result.email ?? '',
            });
        }

        setUserUid(uid);
    };

    const logInWithSocial = async(provider: 'google' | 'apple') => {
        if(provider === 'google') {
            setIsLogInWithGoogleLoading(true);
        } else {
            setIsLogInWithAppleLoading(true);
        }

        try {
            const result = provider === 'google' ? await signInWithGoogle() : await signInWithApple();

            track('Sign in', {new_user: result.isNewUser, type: provider});

            await setSocialUser(result);

            return {isSuccess: true, isNewUser: result.isNewUser};
        } catch(e) {
            const message = get(e, 'message');
            if(message && isString(message) && message.toLowerCase().includes('code=-5')){
                return;
            }
            setActionError(e);
            console.error(e);
        } finally {
            setIsLogInWithGoogleLoading(false);
            setIsLogInWithAppleLoading(false);
        }
    };

    const registerWithEmailAndPassword = async(email: string, password: string, extraData: Partial<User>) => {
        setRegisterLoading(true);
        try {
            const result = await createUserWithEmailAndPassword(email.trim(), password.trim());
            const {user} = result;
            await createUser({
                email: user.email ?? '',
                firstName: extraData.firstName ?? t('auth.defaultUser.firstName'),
                lastName: extraData.lastName ?? t('auth.defaultUser.lastName'),
                username: extraData.username ?? t('auth.defaultUser.username'),
            });
            track('Sign up', {new_user: true, type: 'email'});
            setUserUid(user.uid);
            return true;
        } catch(e) {
            setActionError(e);
            console.error(e);
        } finally {
            setRegisterLoading(false);
        }
    };

    const logOut = async() => {
        setLogOutLoading(true);
        try {
            setUserUid(undefined);
            setAclLastUpdated(undefined);
            setIsAdmin(false);
            setIsTournamentOrganizer(false);
            await signOut();
            return true;
        } catch(e) {
            setActionError(e);
            console.error(e);
        } finally {
            setLogOutLoading(false);
        }
    };

    const logIn = async(email: string, pass: string, remember: boolean) => {
        setLogInLoading(true);
        try {
            await setPersistence(remember);
            const user = await signInWithEmailAndPassword(email.trim(), pass.trim());
            setUserUid(user.user.uid);
            track('Sign in', {new_user: false, type: 'email'});
            return true;
        } catch(e) {
            setActionError(e);
            console.error(e);
        } finally {
            setLogInLoading(false);
        }
    };

    const setClub = async(club?: ClubUiData) => {
        if(!user) {
            return;
        }

        setIsUpdateUserLoading(true);
        try {
            await updateUser(user.uid, {
                ['sports.golf.club']: club ? club : deleteFieldValue(),
            });
            track('Club set', {club: club?.name});
            return true;
        } catch(e) {
            if(e instanceof FirebaseError) {
                crouton.show({
                    title: t('actions.error'),
                    message: e.message,
                    preset: 'error',
                });
            }
        } finally {
            setIsUpdateUserLoading(false);
        }
    };

    const resetPassword = async(email: string) => {
        setResetPasswordLoading(true);
        try {
            await sendPasswordResetEmail(email.trim());
            track('Password reset', {email});
            return true;
        } catch(e) {
            setActionError(e);
            console.error(e);
        } finally {
            setResetPasswordLoading(false);
        }
    };

    const updateUserPassword = async(currentPassword: string, newPassword: string) => {
        setIsUpdatePasswordLoading(true);
        try {
            if(user?.email) {
                await reauthenticateUser(user?.email, currentPassword.trim());

                await updatePassword(newPassword.trim());

                track('Password updated');

                return true;
            } else {
                return false;
            }
        } catch(e) {
            setActionError(e);
            console.error(e);
        } finally {
            setIsUpdatePasswordLoading(false);
        }
    };

    const onDelete = async() => {
        try {
            const response = await deleteUser();
            if(response) {
                await logOut();
            }
        } catch(e) {
            console.error(e);
            if(e instanceof FirebaseError && e.code === 'auth/user-not-found') {
                await logOut();
            } else {
                setActionError(e);
            }
        }
    };

    const anonymousLogin = async() => {
        setIsAnonymousLoading(true);
        try {
            await logInAnonymously();
            return true;
        } catch(e) {
            console.error(e);
            setActionError(e);
        } finally {
            setIsAnonymousLoading(false);
        }
    };

    const isCompleted = useMemo(() => {
        return isProfileCompleted(user);
    }, [user]);

    return {
        actionError,
        isLoggedIn,
        firebaseUser,
        firebaseLoading,
        logOut,
        logIn,
        user,
        isUserLoading,
        isLoading,
        isError,
        registerWithEmailAndPassword,
        resetPassword,
        updateUserPassword,
        setClub,
        deleteUser: onDelete,
        isDeleteUserLoading,
        isLogInLoading,
        isLogOutLoading,
        isRegisterLoading,
        isResetPasswordLoading,
        isUpdateUserLoading,
        isUpdatePasswordLoading,
        isAnonymousLoading,
        isSocialLogin,
        anonymousLogin,
        logInWithSocial,
        isLogInWithGoogleLoading,
        isLogInWithAppleLoading,
        isCompleted,
        isTournamentOrganizer,
        isAdmin,
    };
};

import React from 'react';
import useAnalyticsTracking from './useAnalyticsTracking';

const AnalyticsTrackingEventsProvider = ({
    children,
}: React.PropsWithChildren) => {
    useAnalyticsTracking();

    return <>{children}</>;
};

export default AnalyticsTrackingEventsProvider;

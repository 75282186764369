import {onSnapshot, updateDoc} from 'firebase/firestore';
import {ACL} from '@shared/models/acl';
import {User} from '@shared/models/user';
import {getAuth} from 'firebase/auth';
import {getDocs} from '@ui/helpers/firebase.web';

const updateUser = (uid: string, data: Partial<User>) => {
    return updateDoc(getDocs().user(uid), data as any);
};

const onACLChanged = (uid: string, callback: (acl: ACL) => void) => {
    onSnapshot(getDocs().acl(uid), (snapshot) => {
        const data = snapshot.data();
        if(data) {
            callback(data);
        }
    });
};

const getIdTokenResultClaims = async(refresh: boolean) => {
    return (await getAuth().currentUser?.getIdTokenResult(refresh))?.claims;
};

export {updateUser, onACLChanged, getIdTokenResultClaims};

import * as ImagePicker from 'expo-image-picker';
import {CreateTeamRequest, CreateTeamResponse} from '@shared/models/team';
import React, {useCallback, useContext} from 'react';
import {Sport} from '@shared/models/sport';
import {UserUiData} from '@shared/models/user';
import {createContext} from 'react';
import useCallable from '@ui/hooks/useCallable';
import useCheckFileSize from '@ui/hooks/useCheckFileSize';

const CreateTeamContext = createContext<ReturnType<typeof useProvideCreateTeam> | undefined>(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useProvider().
export const CreateTeamProvider = ({
    value,
    children,
}: {
    value?: ReturnType<typeof useProvideCreateTeam>;
    children: React.ReactElement
}) => {
    const createTeam = value ?? useProvideCreateTeam();
    return (
        <CreateTeamContext.Provider value={createTeam}>{children}</CreateTeamContext.Provider>
    );
};

// Hook for child components to get the createTeam object ...
// ... and re-render when it changes.
export const useCreateTeam = () => {
    return useContext(CreateTeamContext)!;
};

// Provider hook that creates createTeam object and handles state
export const useProvideCreateTeam = () => {
    const [players, setPlayers] = React.useState<UserUiData[]>([]);
    const [imagePickerResult, setImagePickerResult] = React.useState<ImagePicker.ImagePickerAsset>();
    const [teamName, setTeamName] = React.useState<string>('');
    const [createTeamCallable, isCreateTeamLoading] = useCallable<CreateTeamRequest, CreateTeamResponse>('team-create');
    const {checkFileSize} = useCheckFileSize();

    const isButtonDisabled = teamName === '';

    const addPlayer = useCallback((player: UserUiData) => {
        setPlayers(players => [...players, player]);
    }, [setPlayers]);

    const removePlayer = useCallback((player: UserUiData) => {
        setPlayers(players => players.filter(p => p.id !== player.id));
    }, [setPlayers]);

    const isUserInTeam = useCallback((player: UserUiData) => {
        return players.some(p => p.id === player.id);
    }, [players]);

    const clearData = useCallback(() => {
        setPlayers([]);
        setImagePickerResult(undefined);
        setTeamName('');
    }, [setPlayers, setImagePickerResult, setTeamName]);

    const createTeam = async(id: string) => {
        if(isCreateTeamLoading || isButtonDisabled){
            return;
        }

        if(checkFileSize(imagePickerResult) === false) {
            return;
        }

        const response = await createTeamCallable({
            id,
            username: teamName.trim(),
            uids: players.map(player => player.uid),
            sport: Sport.Golf,
            ...(imagePickerResult?.base64 && {avatar: imagePickerResult.base64}),
        });

        if(!response) {
            return;
        }

        return response.id;
    };

    return {
        players,
        addPlayer,
        removePlayer,
        isUserInTeam,
        imagePickerResult,
        setImagePickerResult,
        teamName,
        setTeamName,
        isButtonDisabled,
        isCreateTeamLoading,
        createTeam,
        clearData,
    };
};

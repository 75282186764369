import Mixpanel from 'mixpanel-browser';

let mixpanelInstance: Mixpanel.Mixpanel | null = null;

function getMixpanelInstance() {
    if (!mixpanelInstance) {
        mixpanelInstance = Mixpanel.init(
            process.env.NEXT_PUBLIC_MIX_PANEL_PROJECT_TOKEN ?? '',
            {debug: process.env.NODE_ENV === 'development', track_pageview: true, persistence: 'localStorage'},
        );
    }

    return mixpanelInstance;
}

const track = (event?: string, properties?: any) => {
    if(!event) {
        return;
    }

    getMixpanelInstance()?.track(event, {
        ...properties,
    });
};

const identify = (uid: string) => {
    getMixpanelInstance()?.identify(uid);
};

const reset = () => {
    getMixpanelInstance()?.reset();
};

export {
    track,
    identify,
    reset,
};

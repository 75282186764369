import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const IcWarning = (props: SvgProps) => (
    <Svg
        viewBox='0 0 24 24'
        width={24}
        height={24}
        fill="#fff"
        {...props}
    >
        <Path
            d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z"
        />
    </Svg>
);

export default IcWarning;


import Toast from 'react-native-toast-message';

  interface ToastProps {
    title: string;
    message?: string;
    preset?: 'error' | 'success' | 'danger';
}

const useCrouton = () => {
    const show = async(props: ToastProps) => {
        const {
            title,
            message,
            preset = 'success',
        } = props;

        Toast.show({
            text1: title,
            text2: message,
            type: preset,
        });
    };

    return {
        show,
    };
};


export default useCrouton;

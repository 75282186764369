import {
    AuthProvider,
    EmailAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    UserCredential,
    browserLocalPersistence,
    browserSessionPersistence,
    createUserWithEmailAndPassword as createWithEmailAndPassword,
    getAdditionalUserInfo,
    getAuth,
    getRedirectResult,
    reauthenticateWithCredential,
    sendPasswordResetEmail as sendPasswordReset,
    signInAnonymously,
    signInWithEmailAndPassword as signInWithEmail,
    signInWithPopup,
    signInWithRedirect,
    signOut,
    updatePassword as updatePass,
} from 'firebase/auth';
import {SocialLoginResult} from '@ui/provider/user/types';
import {useAuthState as useAuthStateHook} from 'react-firebase-hooks/auth';

const createUserWithEmailAndPassword = async(email: string, password: string) => createWithEmailAndPassword(getAuth(), email, password);

const signInWithEmailAndPassword = async(email: string, password: string) => signInWithEmail(getAuth(), email, password);

const setPersistence = async(remember: boolean) => getAuth().setPersistence(
    remember
        ? browserLocalPersistence
        : browserSessionPersistence,
);

const sendPasswordResetEmail = async(email: string) => sendPasswordReset(getAuth(), email);

const updatePassword = async(password: string) => updatePass(getAuth().currentUser!, password);

const reauthenticateUser = async(email: string, password: string) => reauthenticateWithCredential(getAuth().currentUser!, EmailAuthProvider.credential(email, password));

const useAuthState = () => useAuthStateHook(getAuth());

const logInAnonymously = () => signInAnonymously(getAuth());

const logOut = () => signOut(getAuth());

const returnUserCredentialResult = (userCredential: UserCredential): SocialLoginResult => {
    const {isNewUser} = getAdditionalUserInfo(userCredential)!;
    const {user, providerId} = userCredential;
    const username = user.displayName;

    const firstName = user.displayName?.split(' ')[0];
    const lastName = user.displayName?.split(' ')[1];

    return {
        uid: user.uid,
        isNewUser,
        username,
        firstName,
        lastName,
        ...(providerId?.toLocaleLowerCase().includes('apple') && {email: user.email}),
        ...(providerId?.toLocaleLowerCase().includes('google') && {uid: user.uid, email: user.email}),
    };
};

const checkRedirectResult = async(): Promise<SocialLoginResult | undefined> => {
    const result = await getRedirectResult(getAuth());

    if(!result) {
        return undefined;
    }

    if(result.providerId?.toLocaleLowerCase().includes('google')) {
        GoogleAuthProvider.credentialFromResult(result);
    } else {
        OAuthProvider.credentialFromResult(result);
    }

    return returnUserCredentialResult(result);
};

const signInWithProvider = async(provider: AuthProvider): Promise<SocialLoginResult> => {
    try {
        const result = await signInWithPopup(getAuth(), provider);

        return returnUserCredentialResult(result);
    } catch(error: any) {
        if(error.message.toLowerCase().includes('popup-blocked')) {
            await signInWithRedirect(getAuth(), provider);
        }

        throw error;
    }
};

const signInWithGoogle = async() => {
    const provider = new GoogleAuthProvider();
    return await signInWithProvider(provider);
};

const signInWithApple = async() => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return await signInWithProvider(provider);
};

export {
    checkRedirectResult,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    logOut as signOut,
    setPersistence,
    sendPasswordResetEmail,
    updatePassword,
    useAuthState,
    logInAnonymously,
    reauthenticateUser,
    signInWithGoogle,
    signInWithApple,
};

import {Box, useBreakpointValue} from 'native-base';
import BottomsheetWamp from '@ui/components/sheet/Bottomsheet';
import OnboardingScreen from '@ui/features/onboarding/OnboardingScreen';
import React from 'react';
import {useIsFirstAppStartUp} from './hooks/useIsFirstAppStartUp';

const OnboardingProvider = ({
    children,
}: {
    children: React.ReactElement
}) => {
    const {isFirstAppStartUp, setFirstAppStartUp} = useIsFirstAppStartUp();
    const snapPoints = useBreakpointValue({base: ['100%'], lg: ['90%']});
    const isSmallScreen = useBreakpointValue({base: true, lg: false});

    return (
        <>
            {children}

            {isFirstAppStartUp && !isSmallScreen &&
            <BottomsheetWamp
                isOpen={isFirstAppStartUp}
                onClose={setFirstAppStartUp}
                _bottomSheetProps={{
                    enablePanDownToClose: false,
                    snapPoints: snapPoints,
                    handleComponent: () => <Box />,
                    style: {overflow: 'hidden', borderRadius: 20},
                }}
            >
                <OnboardingScreen onFinish={setFirstAppStartUp} />
            </BottomsheetWamp>}
        </>
    );
};

export default OnboardingProvider;

import {Center, Row, Text, useTheme} from 'native-base';
import {StyleSheet, TouchableOpacity} from 'react-native';
import IcBackButton from '@ui/svg/IcBackButton';
import IcCircleCross from '@ui/svg/IcCircleCross';
import React from 'react';

interface Props {
    onClose: () => void;
    onBack?: () => void;
    title?: string;
    Left?: React.ReactNode;
}

const BottomSheetHandle = (props: Props) => {
    const {onClose, onBack, Left, title = ''} = props;
    const theme = useTheme();

    return (
        <Row alignItems="center" mt={2} px='4' pt='2'>
            {Left}

            <TouchableOpacity onPress={onBack} style={styles.container}>
                <Center h='4' w='4'>
                    {onBack && <IcBackButton fill='black' animatedProps={{fill: 'black'}}/>}
                </Center>
            </TouchableOpacity>

            <Text textAlign='center' variant='medium'>{title}</Text>

            <TouchableOpacity onPress={onClose} style={styles.reverse}>
                <IcCircleCross border={theme.colors.secondary[100]} fill='white' stroke='black' width={32} height={32} strokeWidth={1}/>
            </TouchableOpacity>
        </Row>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    reverse: {
        flex: 1,
        flexDirection: 'row-reverse',
    },
});

export default BottomSheetHandle;

import {Box, Button, Column, Row, ScrollView, StatusBar, Text} from 'native-base';
import React, {useMemo, useState} from 'react';
import {SceneMap, TabView} from 'react-native-tab-view';
import {ImageUris} from '@shared/models/image';
import WAMPImage from '@ui/components/image/WAMPImage';
import {useDashboardAssets} from '../clubhouse/hooks/dashboard-hooks';
import {useTranslation} from 'react-i18next';

interface OnboardingViewProps {
    image: ImageUris;
    title: string;
    description: string;
}

const OnboardingView = (props: OnboardingViewProps) => {
    const {image, title, description} = props;

    return (
        <Column flex={1} space='4' alignItems="center">
            <Box flex={2} w='full'>
                <WAMPImage
                    contentFit='contain'
                    src={image.xl}
                    image={image}
                    fill
                    alt="image"
                />

            </Box>

            <Column flex={1} space='4' alignItems="center" justifyContent="center" px='4'>
                <Text variant='h4' color='white' textAlign="center">{title}</Text>

                <Text variant='bodyLg' textAlign='center' color='white'>{description}</Text>
            </Column>
        </Column>
    );
};

interface Props {
    onFinish: () => void;
}

const OnboardingScreen = (props: Props) => {
    const {onFinish} = props;
    const [[index], setState] = useState([0, 0]);
    const [dashboardConfig] = useDashboardAssets();
    const {t} = useTranslation();

    const onboardingItems = dashboardConfig?.onboarding?.items ?? [];

    const isLastPage = index === onboardingItems.length - 1;


    const onPress = () => {
        if (isLastPage) {
            onFinish();
        } else {
            setState([index + 1, 1]);
        }
    };

    const handleIndexChange = (i: number) => {
        setState([i, i > index ? 1 : -1]);
    };

    const routes = useMemo(() => onboardingItems.map((_, i) => ({key: i.toString(), title: ''})), [onboardingItems]);

    const renderScene = useMemo(() => SceneMap(
        onboardingItems.reduce((acc, item, i) => {
            acc[i] = () => <OnboardingView title={item.title.en} description={item.description.en} image={item.image}/>;
            return acc;
        }, {} as Record<number, () => JSX.Element>),
    ), [onboardingItems]);

    return (
        <Box
            bg='darkGreen'
            flex={1}
            safeArea
        >
            <StatusBar barStyle='light-content' />

            <ScrollView style={style} contentContainerStyle={style}>
                <Column flex={1} p='4'>
                    <Button variant='ghost' _text={{color: 'white'}} pb='4' onPress={onFinish} alignSelf='flex-end'>
                        {t('actions.skip')}
                    </Button>

                    {onboardingItems.length > 1 &&
                <>
                    <Box minH={400} flex={1}>
                        <TabView
                            navigationState={{index, routes}}
                            renderScene={renderScene}
                            onIndexChange={handleIndexChange}
                            renderTabBar={() => null}
                        />
                    </Box>

                    <Row alignItems='center' justifyContent='center' space='2' flex={1}>
                        {onboardingItems.map((_, i) => (
                            <Box key={i} w='2' h='2' borderRadius='full' bg="white" opacity={i === index ? 1 : 0.2} />
                        ))}
                    </Row>

                    <Button variant={isLastPage ? 'lime' : 'white'} onPress={onPress}>
                        {isLastPage ? t('actions.getStarted') : t('actions.continue')}
                    </Button>
                </>
                    }
                </Column>
            </ScrollView>
        </Box>
    );
};

const style = {flex: 1};

export default OnboardingScreen;

import React, {useContext} from 'react';
import {createContext} from 'react';
import {useUser} from '@ui/provider/user/UserProvider';
import {useWallet} from './hooks/use-wallet';

const WalletContext = createContext<ReturnType<typeof useProvideWallet> | undefined>(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useProvider().
export const WalletProvider = ({
    children,
}: {
    children: React.ReactElement
}) => {
    const wallet = useProvideWallet();
    return (
        <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>
    );
};

// Hook for child components to get the userRegistration object ...
// ... and re-render when it changes.
export const useUserWallet = () => {
    return useContext(WalletContext)!;
};

// Provider hook that creates userRegistration object and handles state
const useProvideWallet = () => {
    const {user} = useUser();
    const [wallet, isWalletLoading] = useWallet(user?.uid);

    return {
        isWalletLoading,
        wallet,
    };
};

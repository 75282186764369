
enum WalletEventType {
    LOG_IN = 'log_in',
    WIN_BET = 'win_bet',
    LOSE_BET = 'lose_bet',
    PLAY_BET = 'play_bet',
    PLAY_DRILL = 'play_drill',
}
const WalletEventTypes = Object.values(WalletEventType);

interface Event {
    id: string;
    uid: string;
    createdAt: Date;
    type: WalletEventType;
}
interface EventLogRequest {
    type: WalletEventType;
}
interface EventLogResponse {
    id: string;
}

export type {
    Event,
    EventLogRequest,
    EventLogResponse,
};

export {
    WalletEventTypes,
    WalletEventType,
};

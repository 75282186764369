import {Entity, EntityPublicData} from '@shared/models/entity';
import React, {useContext, useEffect, useState} from 'react';
import {createContext} from 'react';
import useCrouton from '@ui/hooks/useCrouton';
import {usePublicEntityDoc} from './hooks/use-entity';
import {useTranslation} from 'react-i18next';

const EntityContext = createContext<ReturnType<typeof useProvideEntity> | undefined>(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useProvider().
export const EntityProvider = ({
    children,
    value,
}: {
    children: React.ReactElement,
    value?: ReturnType<typeof useProvideEntity>,
}) => {
    const entity = value ?? useProvideEntity();
    return (
        <EntityContext.Provider value={entity}>{children}</EntityContext.Provider>
    );
};

// Hook for child components to get the entity object ...
// ... and re-render when it changes.
export const useEntity = () => {
    return useContext(EntityContext)!;
};

// Provider hook that creates entity object and handles state
const useProvideEntity = () => {
    const [entityId, setEntityId] = useState<string>();
    const [selectedEntity, setSelectedEntity] = useState<Entity | EntityPublicData>();

    const [entity, isEntityLoading, error] = usePublicEntityDoc(entityId);
    const {t} = useTranslation();
    const crouton = useCrouton();

    useEffect(() => {
        if(error){
            crouton.show({
                title: t('actions.error'),
                message: error.message,
                preset: 'error',
            });
        }
    }, [error]);

    const setSelectedEntityId = (id: string) => {
        setEntityId(id);
        if(selectedEntity && selectedEntity.id !== id){
            setSelectedEntity(undefined);
        }
    };

    const isLoading = entityId !== selectedEntity?.id && isEntityLoading;

    return {
        entity: entity ?? selectedEntity,
        isEntityLoading: isLoading,
        setSelectedEntityId,
        setSelectedEntity,
        entityId,
    };
};

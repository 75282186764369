import {getCollections, getDocs} from '@ui/helpers/firebase.web';
import {getCountFromServer, getDoc, query, where} from 'firebase/firestore';
import {useEffect, useState} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';

const useUserDoc = (uid?: string) => useDocumentData(uid ? getDocs().user(uid) : undefined);

const userExists = async(uid: string) => {
    return (await getDoc(getDocs().publicUser(uid))).exists();
};

const useUserCount = () => {
    const [count, setCount] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any | undefined>(undefined);

    const getCount = async() => {
        setIsLoading(true);
        setError(undefined);
        try{
            const users = await getCountFromServer(getCollections().users);
            const anonUsers = await getCountFromServer(query(getCollections().users, where('isAnonymous', '==', true)));
            setCount(users.data().count - anonUsers.data().count);
        }catch(e){
            setError(e);
        }finally{
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCount();
    }, []);

    return [count, isLoading, error] as const;
};

export {useUserDoc, userExists, useUserCount};


import {
    getFunctions,
    httpsCallable,
} from 'firebase/functions';
import {useCallback, useState} from 'react';

export type HttpsCallableHook<
    RequestData = unknown,
    ResponseData = unknown
  > = Readonly<
    [
      (
        data?: RequestData
      ) => Promise<ResponseData | undefined>,
      boolean,
      Error | undefined
    ]
  >;

export default <RequestData, ResponseData>(
    name: string,
): HttpsCallableHook<RequestData, ResponseData> => {
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState<boolean>(false);

    const callCallable = useCallback(
        async(
            data?: RequestData,
        ): Promise<ResponseData | undefined> => {
            const callable = httpsCallable<RequestData, ResponseData>(
                getFunctions(),
                name,
            );
            setLoading(true);
            setError(undefined);
            try {
                return (await callable(data)).data;
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        },
        [name],
    );

    return [callCallable, loading, error] as const;
};

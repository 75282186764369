import {get} from '@shared/helpers/lang';

export const convertAuthErrorKey = (t: (v:string) => string, e?: unknown) => {
    switch (get(e, 'code')) {
        case 'auth/invalid_email':
        case 'auth/invalid-email':
            return t('auth.errors.invalidEmail');
        case 'auth/weak-password':
            return t('auth.errors.weakPassword');
        case 'auth/email-already-in-use':
            return t('auth.errors.emailAlreadyInUse');
        case 'auth/user-not-found':
            return t('auth.errors.userNotFound');
        case 'auth/wrong-password':
            return t('auth.errors.wrongPassword');
        default:
            return get(e, 'message');
    }
};

import {ImageUris} from '@shared/models/image';
import React from 'react';
import {SolitoImage} from 'solito/image';
import {SolitoImageProps} from 'solito/build/image/image.types';

type Props = SolitoImageProps & {
    image: ImageUris;
}

const WAMPImage = (props: Props) => {
    const {image, ...rest} = props;

    const placeholder = image?.placeholder;

    return (
        <SolitoImage
            placeholder={placeholder ? 'blur' : undefined}
            blurDataURL={placeholder}
            {...rest}
        />
    );
};

export default WAMPImage;

import {EventLogRequest, EventLogResponse, WalletEventType} from '@shared/models/wallet/event';
import useHttpsCallable from '@ui/hooks/firebase/functions';

const useLogWalletEvent = () => {
    const [logWalletEvent] = useHttpsCallable<EventLogRequest, EventLogResponse>('httpsWalletLogEvent-default');

    const onLogEvent = async(type: WalletEventType): Promise<void> => {

        await logWalletEvent({type});
    };

    return {onLogEvent};
};

export default useLogWalletEvent;

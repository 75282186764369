import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const IcError = (props: SvgProps) => (
    <Svg
        viewBox='0 0 24 24'
        width={24}
        height={24}
        fill="#fff"
        {...props}
    >
        <Path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm1 15h-2v-2h2v2Zm0-4h-2V7h2v6Z"
        />
    </Svg>
);

export default IcError;


import {extendTheme} from 'native-base';

const nativeBaseTheme = extendTheme({
    colors: {
        black: '#151515',
        gold: '#FBD87D',
        blacks: {
            100: '#F1F1F1',
            200: '#E2E2E2',
            300: '#D3D3D3',
            400: '#ABABAB',
            500: '#919191',
            600: '#777777',
            700: '#5E5E5E',
            900: '#303030',
        },
        primary: {
            0: '#E5F2FF',
            100: '#CCE5FF',
            200: '#99CAFF',
            300: '#66B0FF',
            400: '#3395FF',
            500: '#007BFF',
            600: '#0062CC',
            700: '#004A99',
            800: '#003166',
            900: '#001933',
        },
        secondary: {
            0: '#F9F9F9',
            100: '#EDEDED',
            200: '#E1E1E1',
            300: '#D3D3D3',
            400: '#C4C4C4',
            500: '#B3B3B3',
            600: '#A0A0A0',
            700: '#898989',
            800: '#6C6C6C',
            900: '#3F3F3F',
        },
        danger: {
            100: '#FFF1F2',
            500: '#F43F5E',
            600: '#BE123C',
            800: '#881337',
        },
        warning: {
            100: '#FFF7ED',
            400: '#FB9804',
            500: '#F97316',
            600: '#C2410C',
            800: '#7C2D12',
        },
        success: {
            100: '#ECFDF5',
            400: '#45CF53',
            500: '#34D399',
            600: '#10B981',
            800: '#065F46',
        },
        critical: {
            500: '#F63D4D',
        },
        info: {
            100: '#F0F9FF',
            500: '#0EA5E9',
            600: '#0284C7',
            800: '#075985',
        },
        lime: {
            default: '#C1F979',
            50: '#f2ffde',
            100: '#e1ffaf',
            200: '#cffe7f',
            300: '#CFFE80',
            400: '#aafe1f',
            500: '#90e408',
            600: '#70b101',
            700: '#4f7f00',
            800: '#2e4c00',
            900: '#0d1a00',
        },
        purple: {
            default: '#4C02EA',
            500: '#a855f7',
        },
        darkGreen: '#003A23',
        darkerGreen: '#001F15',
        greenBackgroundLight: '#EBEFED',
        background: '#E5E5E5',
        backgroundLight: '#F7F7F7',
        LIVPink: '#F055D9',
    },
    sizes: {
        14: 56,
        18: 72,
        22: 88,
        24: 96,
    },
    fontConfig: {
        Inter: {
            400: {
                normal: 'Inter-Regular',
            },
            500: {
                normal: 'Inter-Medium',
            },
            600: {
                normal: 'Inter-SemiBold',
            },
        },
        Strong: {
            400: {
                normal: 'BornStrong-Regular',
            },
            500: {
                normal: 'BornStrong-Medium',
            },
            600: {
                normal: 'BornStrong-Bold',
                italic: 'BornStrong-BoldItalic',
            },
            900:{
                normal: 'BornStrong-Black',
                italic: 'BornStrong-BlackItalic',
            },
        },
    },
    fonts: {
        Inter: 'Inter',
        Strong: 'Strong',
    },
    components: {
        Button: {
            sizes: {
                md: {
                    py: '3',
                },
                lg: {
                    py: '3.5',
                },
            },
            variants: {
                primary: {
                    bg: 'black',
                    _text: {
                        color: 'white',
                        variant: 'mediumBody',
                    },
                    _pressed: {
                        bg: 'secondary.700',
                    },
                    _hover: {
                        bg: 'secondary.800',
                    },
                    _disabled: {
                        bg: 'secondary.500',
                    },
                },
                white: {
                    bg: 'white',
                    _text: {
                        color: 'black',
                        variant: 'mediumBody',
                    },
                    _pressed: {
                        bg: 'secondary.200',
                    },
                    _hover: {
                        bg: 'secondary.100',
                    },
                    _disabled: {
                        bg: 'secondary.100',
                    },
                },
                lime: {
                    bg: 'lime.default',
                    _text: {
                        color: 'black',
                        variant: 'mediumBody',
                    },
                    _pressed: {
                        bg: 'lime.400',
                    },
                    _hover: {
                        bg: 'lime.300',
                    },
                    _disabled: {
                        bg: 'lime.100',
                    },
                },
                ghostPrimary: {
                    bg: 'white',
                    _text: {
                        color: 'black',
                        variant: 'mediumBody',
                    },
                    _pressed: {
                        bg: 'secondary.200',
                    },
                    _hover: {
                        bg: 'secondary.100',
                    },
                    _disabled: {
                        bg: 'secondary.100',
                    },
                },
                ghostOutline: {
                    bg: 'transparent',
                    _text: {
                        color: 'black',
                        variant: 'mediumBody',
                    },
                    _pressed: {
                        bg: 'secondary.200',
                    },
                    _hover: {
                        opacity: 0.8,
                    },
                    _disabled: {
                        bg: 'secondary.100',
                    },
                    borderColor: 'secondary.200',
                    borderWidth: '1px',
                },
                ghostOutlineWhite: {
                    bg: 'transparent',
                    _text: {
                        color: 'white',
                        variant: 'mediumBody',
                    },
                    _pressed: {
                        bg: 'secondary.700',
                    },
                    _hover: {
                        bg: 'secondary.800',
                    },
                    _disabled: {
                        bg: 'secondary.500',
                    },
                    borderColor: 'secondary.200',
                    borderWidth: '1px',
                },
            },
            defaultProps: {
                variant: 'primary',
                _text: {
                    fontSize: 16,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                },
                rounded: 'xl',
            },
        },
        ScrollView: {
            baseStyle: {
                _contentContainerStyle: {
                    flexGrow: 1,
                },
            },
        },
        Divider: {
            baseStyle: {
                bg: 'secondary.100',
            },
        },
        Menu: {
            baseStyle: {
                rounded: 'xl',
                bg: 'white',
            },
        },
        Input: {
            defaultProps: {
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: 14,
                h: '12',
                focusOutlineColor: 'black',
                _hover: {
                    borderColor: 'black',
                },
                _focus: {
                    borderColor: 'black',
                },
            },
        },
        Radio: {
            defaultProps: {
                bg: 'white',
                borderColor: 'secondary.200',

                _checked: {
                    borderColor: 'darkGreen',
                    bg: 'darkGreen',
                    _icon: {
                        color: 'white',
                    },
                    _hover: {
                        borderColor: 'darkGreen',
                        _icon: {
                            color: 'white',
                        },
                    },
                    _pressed: {
                        borderColor: 'darkGreen',
                        _icon: {
                            color: 'white',
                        },
                    },
                    _active: {
                        borderColor: 'darkGreen',
                        _icon: {
                            color: 'white',
                        },
                    },
                },
            },
        },
        SliderThumb: {
            baseStyle: (props: any) => {
                const {colorScheme} = props;
                const {primary} = props.theme.colors;
                const hover = '#003a2382';
                return {
                    borderRadius: 'full',
                    zIndex: 999,
                    alignItems: 'center',
                    justifyContent: 'center',
                    scaleOnPressed: 1,
                    _interactionBox: {
                        position: 'absolute',
                        borderRadius: 'full',
                        zIndex: -1,
                    },
                    _stack: {
                        direction: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        space: 2,
                    },
                    bg: 'darkGreen',
                    _hover: {
                        _web: {
                            outlineWidth: '4px',
                            outlineColor: hover,
                            outlineStyle: 'solid',
                        },
                    },
                    _focus: {
                        _web: {
                            outlineWidth: '2px',
                            outlineColor: hover,
                            outlineStyle: 'solid',
                        },
                    },
                    _pressed: {
                        _interactionBox: {
                            borderWidth: '8',
                            borderColor:  hover,
                        },
                    },

                    _dark: {
                        bg: `${colorScheme}.500`,
                        _hover: {
                            _web: {
                                outlineWidth: '4px',
                                outlineColor:  hover,
                                outlineStyle: 'solid',
                            },
                        },
                        _focus: {
                            _web: {
                                outlineWidth: '2px',
                                outlineColor: hover,
                                outlineStyle: 'solid',
                            },
                        },
                        _pressed: {
                            _interactionBox: {
                                borderWidth: '8',
                                borderColor:  hover,
                            },
                        },
                    },
                    // shadow: 6,
                    _web: {
                        cursor: 'pointer',
                    },
                };
            },
        },
        Text: {
            variants: {
                strongH1: {
                    fontSize: 49,
                    lineHeight: 49,
                    fontFamily: 'Strong',
                    fontWeight: 600,
                },
                h1: {
                    fontSize: 49,
                    lineHeight: 65,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                },
                strongH2: {
                    fontSize: 36,
                    lineHeight: 36,
                    fontFamily: 'Strong',
                    fontWeight: 900,
                },
                h2: {
                    fontSize: 36,
                    lineHeight: 40,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                },
                strongH3: {
                    fontSize: 30,
                    lineHeight: 30,
                    fontFamily: 'Strong',
                    fontWeight: 600,
                },
                h3: {
                    fontSize: 30,
                    lineHeight: 32,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                },
                boldH4: {
                    fontSize: 24,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                },
                strongH4: {
                    fontSize: 24,
                    lineHeight: 24,
                    fontFamily: 'Strong',
                    fontWeight: 600,
                },
                h4: {
                    fontSize: 24,
                    lineHeight: 28,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                },
                boldH5: {
                    fontSize: 20,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                },
                strongH5: {
                    fontSize: 20,
                    lineHeight: 24,
                    fontFamily: 'Strong',
                    fontWeight: 500,
                },
                h5: {
                    fontSize: 20,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                },
                mediumSemiBold: {
                    fontSize: 16,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                },
                medium: {
                    fontSize: 16,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                },
                bodyLg: {
                    fontSize: 16,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                },
                boldBody: {
                    fontSize: 14,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                },
                mediumBody: {
                    fontSize: 14,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                },
                body: {
                    fontSize: 14,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                },
                bodySmBold: {
                    fontSize: 12,
                    lineHeight: 16,
                    fontFamily: 'Inter',
                    fontWeight: 600,
                },
                bodySm: {
                    fontSize: 12,
                    lineHeight: 16,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                },
                smMedium: {
                    fontSize: 12,
                    lineHeight: 16,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                },
                bodyXs: {
                    fontSize: 10,
                    lineHeight: 16,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                },
                button: {
                    fontSize: 16,
                    lineHeight: 24,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                },
            },
        },
    },
});

// 2. Get the type of the CustomTheme
export type CustomThemeType = typeof nativeBaseTheme;

// 3. Extend the internal NativeBase Theme
declare module 'native-base' {
    interface ICustomTheme extends CustomThemeType {}
}

export default nativeBaseTheme;

import {CompetitionGroupUiData} from '@shared/models/competition-group';
import {CompetitionUiData} from '@shared/models/competition';
import {EntityUiData} from './entity';
import {FirestoreTimestamp} from '@shared/models/firestore-timestamp';
import {Participant} from '@shared/models/participant';
import {UserUiData} from '@shared/models/user';

enum RegistrationStatus {
    Registered = 'REGISTERED',
    Unregistered = 'UNREGISTERED',
}

enum RegistrationType {
    Competition = 'COMPETITION',
    Community = 'COMMUNITY',
}

interface Registration {
    id: string;
    type: RegistrationType;
    competitionId?: string;
    competition?: CompetitionUiData;
    competitionGroupId?: string;
    competitionStageId?: string;
    competitionGroup?: CompetitionGroupUiData;
    uid: string;
    // All uids that can access a registration. For example, if a team registers, all team members will be able to access the registration.
    uids: string[];
    user?: UserUiData;
    createdAt: Date | FirestoreTimestamp;
    participant: Participant;
    status: RegistrationStatus;
    paymentIntentId?: string;
    teamId?: string;
    // For community
    entityId?: string;
    entity?: EntityUiData;
}

const registrationCompetitionId = (competitionId: string, uid: string) => `${competitionId}:participant:${uid}`;

const registrationEntityId = (entityId: string, uid: string) => `entity:${entityId}:participant:${uid}`;

export type {Registration};

export {registrationCompetitionId, RegistrationType, RegistrationStatus, registrationEntityId};

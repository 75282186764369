import {Fixture, ResultStatus} from '@shared/models/fixture';
import {getCollections, getDocs} from '@ui/helpers/firebase.web';
import {orderBy, query, where} from 'firebase/firestore';
import {useCollectionData, useDocumentData} from 'react-firebase-hooks/firestore';
import {NewsItemLocations} from '@shared/models/newsItem';
import {subHours} from 'date-fns';

const useDashboardAssets = () => useDocumentData(getDocs().dashboardConfig());

const lastDay = subHours(new Date, 24);

const useVerifiableFixtures = (uid: string) => useCollectionData<Fixture>(
    query(
        getCollections().fixtures,
        where('participantIds', 'array-contains', uid),
        where('result.status' as any, '==', ResultStatus.Pending),
        where('firstScoreUploadedAt', '>=', lastDay),
    ),
);

const useNewsItems = () => useCollectionData(
    getCollections().newsItems,
);

const useNewsItemsSorted = (location: NewsItemLocations) => useCollectionData(
    query(
        getCollections().newsItems,
        where('showLocation', 'array-contains', location),
        orderBy('position', 'asc'),
    ),
);

export {useVerifiableFixtures, useDashboardAssets, useNewsItems, useNewsItemsSorted};

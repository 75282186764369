import React, {useContext} from 'react';
import {Registration} from '@shared/models/registration';
import {createContext} from 'react';
import {useUser} from '@ui/provider/user/UserProvider';
import {useUserRegistrations} from './hooks/user-registration-provider-hooks';

const UserRegistrationContext = createContext<ReturnType<typeof useProvideUserRegistration> | undefined>(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useProvider().
export const UserRegistrationProvider = ({
    children,
}: {
    children: React.ReactElement
}) => {
    const userRegistration = useProvideUserRegistration();
    return (
        <UserRegistrationContext.Provider value={userRegistration}>{children}</UserRegistrationContext.Provider>
    );
};

// Hook for child components to get the userRegistration object ...
// ... and re-render when it changes.
export const useUserRegistration = () => {
    return useContext(UserRegistrationContext)!;
};

// Provider hook that creates userRegistration object and handles state
const useProvideUserRegistration = () => {
    const {user} = useUser();
    const [userRegistrations, isUserRegistrationLoading] = useUserRegistrations(user?.uid);

    const getRegistration = (competitionId?: string): Registration[] => {
        if(!competitionId){
            return [];
        }

        return userRegistrations?.filter(registration => registration.competitionId === competitionId) ?? [];
    };

    return {
        userRegistrations: userRegistrations ?? [],
        isUserRegistrationLoading,
        getRegistration,
    };
};

import {FixtureRule, FixtureRuleType} from './fixture-rule';
import {MicroBet, MicroBetType, Pot} from './pot';
import {Participant, ParticipantType} from './participant';
import {CompetitionGroupUiData} from './competition-group';
import {CompetitionStageUiData} from './competition-stage';
import {CompetitionUiData} from './competition';
import {FirestoreTimestamp} from './firestore-timestamp';
import {II18n} from './I18n';
import {Location} from './location';
import {Scorecard} from './scorecard';
import {Sport} from './sport';
import {TeeBoxDetails} from './teeBox';

enum FixtureStatus {
    Upcoming = 'UPCOMING',
    Delayed = 'DELAYED',
    Postponed = 'POSTPONED',
    Canceled = 'CANCELED',
    Playable = 'PLAYABLE',
    Played = 'PLAYED',
}

const FixtureStatusArray: FixtureStatus[] = [FixtureStatus.Upcoming, FixtureStatus.Delayed, FixtureStatus.Postponed, FixtureStatus.Canceled, FixtureStatus.Playable, FixtureStatus.Played];

enum FixtureType {
    Match = 'MATCH',
    Exercise = 'EXERCISE',
}

interface Score {
    participantId: string;
    score: number;
}

interface ScoresByParticipant {
    scores: Score[];
    winner?: string;
    isDraw: boolean;
    uploadedBy: string;
    uploadedAt: Date;
}

enum ResultStatus {
    Pending = 'PENDING',
    Verified = 'VERIFIED',
    Conflict = 'CONFLICT',
}

type ParticipantsOrder = {[i: number] : string};
type Participants = {[id: string]: Participant};

interface Result {
    scores: Score[];
    winner?: string;
    winners?: string[];
    isDraw?: boolean;
    isBye?: boolean;
    status: ResultStatus;
}

enum KnockoutFixtureType {
    Round = 'ROUND',
    QuarterFinal = 'QUARTER_FINAL',
    SemiFinal = 'SEMI_FINAL',
    Final = 'FINAL',
    ThirdPlace = 'THIRD_PLACE',
}

interface FixtureTeeDetails {
    startingHole?: number;
    teeBoxDetails?: TeeBoxDetails;
}

interface Fixture {
    // Basic data
    id: string;
    // For team fixtures that split
    originalFixtureId?: string;
    name?: II18n;
    description?: II18n;
    descriptionTitle?: II18n;
    startsAt: Date | FirestoreTimestamp;
    endsAt: Date | FirestoreTimestamp;
    playedAt?: Date | FirestoreTimestamp;

    // Creator
    uid?: string;
    // All users that have access to this fixture
    uids: string[];

    // Fixture rules
    rule: FixtureRule;
    sport?: Sport;
    holes?: number;
    // A number between 0 and 100
    handicapAllowance?: number;

    // Participants
    participants: Participants;
    participantsOrder: ParticipantsOrder;
    participantIds: string[];
    participantType: ParticipantType;
    // Tee details
    teeDetails?: FixtureTeeDetails;
    // Invitations
    invitations?: Participants;
    invitationIds?: string[];
    invitationsOrder?: ParticipantsOrder;
    // Competition data (optional)
    competitionId?: string;
    competition?: CompetitionUiData;
    competitionGroup?: CompetitionGroupUiData;
    competitionStage?: CompetitionStageUiData;
    competitionGroupFixtureListId?: string;

    scorecard?: Scorecard;

    location?: Location;
    round?: number;
    stage?: number;
    status: FixtureStatus;
    // Knockout
    knockoutMatch?: boolean;
    knockoutMatchNumber?: number;
    // Next knockout match
    nextKnockoutRoundNumber?: number;
    nextKnockoutMatchNumber?: number;
    nextKnockoutMatchId?: string;
    nextKnockoutMatchLosersNumber?: number;
    nextKnockoutMatchLosersId?: string;
    knockoutFixtureType?: KnockoutFixtureType;
    // Result
    uploadScores?: ScoresByParticipant[];
    result?: Result;
    firstScoreUploadedAt?: Date | FirestoreTimestamp;
    verification?: {
        verifiedBy: 'system' | string;
        verifiedAt: Date | FirestoreTimestamp;
    }
    hasResult: boolean;

    matchIndex?: number;

    // Divots
    pot?: Pot;
    microBets?: MicroBet[];

    // Fixture Type
    fixtureType?: FixtureType;
}

interface AddResultFixtureRequest {
    fixtureId: string;
    result: {
        leftScore?: number;
        rightScore?: number;
        scores?: Score[];
    }
}

interface AddResultFixtureResponse {
    isWinner: boolean;
    isDraw: boolean;
}

interface VerifyFixtureResultRequest {
    fixtureId: string;
}

interface VerifyFixtureResultResponse {
    isSuccessful: boolean;
}

interface CompetitionGroupFixtureList {
    id?: string;
    competitionId: string;
    competitionStageId: string;
    competitionGroupId: string;
    fixtures: { [id: string]: CompetitionGroupFixture; };
    total: number;
    page: number;
    next?: number;
}

// Add participants request
interface AddParticipantsRequest {
    fixtureId: string;
    teamId: string;
    participantIds: string[];
}

// Add participants response
interface AddParticipantsResponse {
    fixtureId: string;
}

interface FixtureParticipantsOrder {
    [i: number]: Participant;
}

// Create fixture request
interface CreateFixtureRequest {
    id: string;
    holes?: number;
    locationId?: string;
    numberOfParticipants: number;
    participantType: ParticipantType;
    ruleType: FixtureRuleType;
    startsAt?: string;
    handicapAllowance?: number;
    divots?: number;
    invitations?: ParticipantsOrder;
    competitionId?: string;
}

// Create fixture response
interface CreateFixtureResponse {
    id: string;
    invitationId: string;
}

interface UpdateFixtureRequest {
    id: string;
    ruleType?: FixtureRuleType;
    startsAt?: string;
    locationId?: string;
    numberOfParticipants?: number;
    divots?: number;
    startingHole?: number;
}

interface DeleteFixtureRequest {
    fixtureId: string;
}

interface DeleteFixtureResponse {
    fixtureId: string;
}

interface UpdateFixtureResponse {
    id: string;
}

interface InviteFixtureRequest {
    fixtureId: string;
    invitations?: ParticipantsOrder;
}

interface InviteFixtureResponse {
    fixtureId: string;
}

interface FixtureDeleteInvitationRequest {
    fixtureId: string;
    inviteeId: string;
}

interface FixtureDeleteInvitationResponse {
    fixtureId: string;
}

interface FixtureRemovePlayerRequest {
    fixtureId: string;
    playerId: string;
}

interface FixtureRemovePlayerResponse {
    fixtureId: string;
}

interface FixtureChangePlayerOrderRequest {
    fixtureId: string;
    playerId: string;
    newSpot: number;
}

interface FixtureChangePlayerOrderResponse {
    fixtureId: string;
}

interface FixtureAddMicroBetRequest {
    fixtureId: string;
    amount: number;
    hole?: number;
    type?: MicroBetType;
    customText?: string;
    uids: string[];
}

interface FixtureAddMicroBetResponse {
    fixtureId: string;
}

interface FixtureAddMicroBetResultRequest {
    fixtureId: string;
    betId: string;
    winnerId?: string;
}

interface FixtureRemoveMicroBetRequest {
    fixtureId: string;
    betId: string;
}

interface FixtureRemoveMicroBetResponse {
    fixtureId: string;
}

interface FixtureAddMicroBetResultResponse {
    fixtureId: string;
}

interface FixtureStartMatchRequest {
    fixtureId: string;
    courseId: string;
    teeIds: string[];
    participantsHandicaps: {[id: string]: number};
    participantsTees: {[id: string]: string};
    handicapAllowance?: number;
}

interface FixtureStartMatchResponse {
    fixtureId: string;
}

interface UpdateScorecardScore {
    uid: string;
    hole: number;
    strokes: number;
}

interface FixtureUpdateScorecardRequest {
    fixtureId: string;
    scores: UpdateScorecardScore[];
}

interface FixtureUpdateScorecardResponse {
    fixtureId: string;
}

type CompetitionGroupFixture = Pick<Fixture, 'id' | 'participantIds' | 'participants' | 'result' | 'round' | 'rule' | 'knockoutMatchNumber' | 'knockoutFixtureType' | 'participantsOrder' | 'scorecard' | 'microBets'>;


const fixtureCompetitionId = (competitionGroupId: string, round: number, fixture: number) => `${competitionGroupId}:round:${round}:fixture:${fixture}`;

export type {
    AddParticipantsRequest,
    AddParticipantsResponse,
    AddResultFixtureRequest,
    AddResultFixtureResponse,
    CompetitionGroupFixture,
    CompetitionGroupFixtureList,
    CreateFixtureRequest,
    CreateFixtureResponse,
    DeleteFixtureRequest,
    DeleteFixtureResponse,
    Fixture,
    FixtureAddMicroBetRequest,
    FixtureAddMicroBetResponse,
    FixtureAddMicroBetResultRequest,
    FixtureAddMicroBetResultResponse,
    FixtureRemoveMicroBetRequest,
    FixtureRemoveMicroBetResponse,
    FixtureParticipantsOrder,
    FixtureChangePlayerOrderRequest,
    FixtureChangePlayerOrderResponse,
    FixtureDeleteInvitationRequest,
    FixtureDeleteInvitationResponse,
    FixtureRemovePlayerRequest,
    FixtureRemovePlayerResponse,
    FixtureStartMatchRequest,
    FixtureStartMatchResponse,
    FixtureTeeDetails,
    FixtureUpdateScorecardRequest,
    FixtureUpdateScorecardResponse,
    InviteFixtureRequest,
    InviteFixtureResponse,
    Participants,
    ParticipantsOrder,
    Result,
    Score,
    ScoresByParticipant,
    VerifyFixtureResultRequest,
    VerifyFixtureResultResponse,
    UpdateFixtureRequest,
    UpdateFixtureResponse,
};

export {fixtureCompetitionId, FixtureStatus, FixtureStatusArray, ResultStatus, KnockoutFixtureType, FixtureType};

import 'intl-pluralrules';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationEN from '@shared/i18n/en.json';

const initI8n = () => {
    const resources = {
        en: {
            translation: translationEN,
        },
    };

    i18n
        .use(initReactI18next)
        .init({
            resources,
            debug: true,
            lng: 'en',
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        });
};

export {initI8n};

import useHttpsCallable, {HttpsCallableHook} from './firebase/functions';
import useCrouton from '@ui/hooks/useCrouton';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const useCallable = <T, R>(name: string, errorTitle?: string): HttpsCallableHook<T, R> => {
    const [callable, loading, error] = useHttpsCallable<T, R>(name);
    const crouton = useCrouton();
    const {t} = useTranslation();

    useEffect(() => {
        if(error !== undefined) {
            crouton.show({
                title: errorTitle ?? t('auth.errors.unknown'),
                message: error.message,
                preset: 'error',
            });
        }
    }, [error]);

    return [callable, loading, error];
};

export default useCallable;

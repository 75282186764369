import {Competition, CompetitionUiPreviewData} from '@shared/models/competition';
import React, {useContext, useEffect, useState} from 'react';
import {createContext} from 'react';
import {useCompetitionDoc} from './hooks/competition-provider-hooks';
import useCrouton from '@ui/hooks/useCrouton';
import {useTranslation} from 'react-i18next';

const CompetitionContext = createContext<ReturnType<typeof useProvideCompetition> | undefined>(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useProvider().
export const CompetitionProvider = ({
    children,
    value,
}: {
    children: React.ReactElement,
    value?: ReturnType<typeof useProvideCompetition>,
}) => {
    const competition = value ?? useProvideCompetition();
    return (
        <CompetitionContext.Provider value={competition}>{children}</CompetitionContext.Provider>
    );
};

// Hook for child components to get the competition object ...
// ... and re-render when it changes.
export const useCompetition = () => {
    return useContext(CompetitionContext)!;
};

// Provider hook that creates competition object and handles state
export const useProvideCompetition = () => {
    const [competitionId, setCompetitionId] = useState<string>();
    const [selectedCompetition, setSelectedCompetition] = useState<Competition | CompetitionUiPreviewData>();

    const [competition, isCompetitionLoading, error] = useCompetitionDoc(competitionId);

    const {t} = useTranslation();
    const crouton = useCrouton();

    useEffect(() => {
        if(error){
            crouton.show({
                title: t('actions.error'),
                message: error.message,
                preset: 'error',
            });
        }
    }, [error]);

    const setSelectedCompetitionId = (id: string) => {
        setCompetitionId(id);
        if(selectedCompetition && selectedCompetition.id !== id){
            setSelectedCompetition(undefined);
        }
    };

    const isLoading = competitionId !== selectedCompetition?.id && isCompetitionLoading;

    return {
        competition: competition ?? selectedCompetition,
        isCompetitionLoading: isLoading,
        setSelectedCompetitionId,
        setSelectedCompetition,
        competitionId,
    };
};

import {Box, Column, ITextProps, Row, Text} from 'native-base';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import IcError from '@ui/svg/IcError';
import IcSuccess from '@ui/svg/IcSuccess';
import IcWarning from '@ui/svg/IcWarning';
import React from 'react';
import {ToastConfig} from 'react-native-toast-message';


interface ToastProps extends IHStackProps {
    text1: string | undefined;
    text2: string | undefined;
    Icon: React.ReactNode;
    _text2?: ITextProps;
}

const Toast = (props: ToastProps) => {
    const {text1, text2, Icon, _text2, ...rest} = props;

    return (
        <Column
            p={4}
            bg='black'
            flex={1}
            rounded="xl"
            {...rest}
        >
            <Row alignItems="center">
                <Box w={8}>
                    {Icon}
                </Box>

                <Text color="white" variant="boldBody">{text1}</Text>
            </Row>

            <Text color="secondary.300" pl={8} {..._text2}>{text2}</Text>
        </Column>
    );
};

interface SubToastProps extends IHStackProps {
    text1: string | undefined;
    text2: string | undefined;
    Icon?: React.ReactNode;
}

const SuccessToast = (props: SubToastProps) => {
    return (
        <Toast Icon={<IcSuccess width={24} height={24} fill='#C1F979'/>} {...props}/>
    );
};

const ErrorToast = (props: SubToastProps) => {
    return (
        <Toast Icon={<IcError width={24} height={24} />} bg='critical.500' _text2={{color: 'blacks.200'}} {...props}/>
    );
};

const WarningToast = (props: SubToastProps) => {
    return (
        <Toast Icon={<IcWarning width={24} height={24} />} bg='warning.500' _text2={{color: 'blacks.200'}} {...props}/>
    );
};

export const toastConfig: ToastConfig = {
    success: ({text1, text2}) => (
        <ToastWrapper>
            <SuccessToast text1={text1} text2={text2}/>
        </ToastWrapper>
    ),
    error: ({text1, text2}) => (
        <ToastWrapper>
            <ErrorToast text1={text1} text2={text2}/>
        </ToastWrapper>
    ),
    danger: ({text1, text2}) => (
        <ToastWrapper>
            <WarningToast text1={text1} text2={text2} />
        </ToastWrapper>
    ),
};

const ToastWrapper = ({children}: {children: JSX.Element}) => {
    return (
        <Box
            w='full'
            px={{
                base: 6,
                lg: '30%',
            }}
            py={{
                base: 6,
                lg: 0,
            }}
        >
            {children}
        </Box>
    );
};

export {SuccessToast, ErrorToast, WarningToast};

import {BottomSheetBackdrop, BottomSheetModal, BottomSheetProps as GorhomBottomSheetProps} from '@gorhom/bottom-sheet';
import {Box, Center, IBoxProps, Row, Text, useBreakpointValue} from 'native-base';
import React, {useEffect, useRef, useState} from 'react';
import {StyleSheet, TouchableOpacity, useWindowDimensions} from 'react-native';
import {get, isArray, isString} from 'lodash';
import BottomSheetHandle from './BottomSheetHandle';
import BottomsheetProps from './BottomsheetProps';
import IcBackButton from '@ui/svg/IcBackButton';
import {SharedValue} from 'react-native-reanimated';
import {useBottomSheetBack} from './useBottomsheet';
import {useSafeArea} from '@ui/provider/safe-area/use-safe-area';

type SnapPointsType = SharedValue<(number | string)[]>;

// When snapPoint is a string, it is a percentage of the window height
const calculateVerticalInset = (windowHeight: number, snapPoint: string | number) => {
    if(isString(snapPoint)) {
        return windowHeight - calculateBottomSheetHeight(windowHeight, snapPoint);
    }

    return windowHeight - snapPoint * 2;
};

const calculateBottomSheetHeight = (windowHeight: number, snapPoint: string | number) => {
    if(isString(snapPoint)) {
        const percentage = parseInt(snapPoint.replace('%', ''));
        return (windowHeight / 100) * percentage;
    }

    return snapPoint;
};

interface Props extends BottomsheetProps {
    _bottomSheetProps?: Partial<GorhomBottomSheetProps>;
    title?: string;
    onBack?: () => void;
    _wrapper?: IBoxProps;
}

const BottomsheetWamp = (props: Props) => {
    const {isOpen, children, title, onBack, _bottomSheetProps = {}, _wrapper = {}} = props;
    const {style = {}, ..._bottomSheetPropsRest} = _bottomSheetProps;
    const md = useBreakpointValue({base: false, md: true});
    const {width, height} = useWindowDimensions();
    const bottomSheetRef = useRef<BottomSheetModal>(null);

    const {top} = useSafeArea();
    const [bottomSheetOpen, setBottomSheetOpen] = useState(false);

    useBottomSheetBack(bottomSheetOpen, bottomSheetRef, () =>
        setBottomSheetOpen(false),
    );


    useEffect(() => {
        if(isOpen) {
            bottomSheetRef.current?.present();
            setBottomSheetOpen(true);
        } else {
            bottomSheetRef.current?.dismiss();
            setBottomSheetOpen(false);
        }
    }, [isOpen, bottomSheetRef.current]);

    const snapPoints = _bottomSheetPropsRest.snapPoints || ['75%'];
    const finalSnapPoint = isArray(snapPoints) ? snapPoints[snapPoints.length - 1] : (snapPoints as SnapPointsType).value[(snapPoints as SnapPointsType).value.length - 1];
    const verticalInset = calculateVerticalInset(height, finalSnapPoint);

    const handleSheetChanges = (index: number) => {
        if(index === -1) {
            props.onClose();
        }
    };

    const onClose = () => {
        props.onClose();
        bottomSheetRef.current?.dismiss();
        bottomSheetRef.current?.close();
    };


    const bottomSheetWidth = get(props?._bottomSheetProps?.style, 'width', 440);
    const bottomsheetStyle = md ? [style, {width: bottomSheetWidth, marginHorizontal: (width - bottomSheetWidth) / 2}] : style;

    return (
        <BottomSheetModal
            ref={bottomSheetRef}
            index={0}
            enablePanDownToClose
            snapPoints={snapPoints}
            onChange={handleSheetChanges}
            keyboardBlurBehavior='restore'
            detached={md}
            style={bottomsheetStyle}
            bottomInset={md ? verticalInset : 0}
            keyboardBehavior='fillParent'
            enableDismissOnClose
            backdropComponent={(props) => <BottomSheetBackdrop disappearsOnIndex={-1} appearsOnIndex={0} {...props} />}
            handleComponent={() => <BottomSheetHandle onClose={onClose} title={title} onBack={onBack}/>}
            topInset={top}
            {..._bottomSheetPropsRest}
        >
            <Box flex={1} bg='white' rounded="xl" {..._wrapper}>
                {children}
            </Box>
        </BottomSheetModal>
    );
};

interface TopBarProps {
    onBack?: () => void;
    title: string;
}

export const BottomsheetTopBar = (props: TopBarProps) => {
    const {onBack, title} = props;

    return (
        <Row px='4' py='5' alignItems='center'>
            <TouchableOpacity onPress={onBack} style={styles.container}>
                <Center h='4' w='4'>
                    {onBack && <IcBackButton fill='black' animatedProps={{fill: 'black'}}/>}
                </Center>
            </TouchableOpacity>

            <Text flex={4} textAlign='center' variant='mediumBody'>{title}</Text>

            <Box flex={1}></Box>
        </Row>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});

export default BottomsheetWamp;

import {identify, reset, track} from '@ui/helpers/analytics/analytics';
import {logEvent, setUserId} from 'firebase/analytics';
import {useCallback, useEffect} from 'react';
import Router from 'next/router';
import {analytics} from '@ui/helpers/firebase.web';
import {useUser} from '../user/UserProvider';

const useTrackSignedInUser = () => {
    const {user} = useUser();
    const uid = user?.uid;

    useEffect(() => {
        if(!analytics) {
            return;
        }

        if (uid) {
            setUserId(analytics, uid);
            identify(uid);
        } else {
            setUserId(analytics, null);
            reset();
        }
    }, [uid]);
};


function useTrackScreenViews() {
    const onRouteChangeComplete = useCallback(() => {
        if(!analytics) {
            return;
        }

        const title = window.location.pathname;
        const params = window.location.search;

        logEvent(analytics, 'screen_view', {
            firebase_screen: title,
            firebase_screen_class: title,
        });

        track('Screen Viewed', {
            screen: title,
            params,
        });
    }, []);

    useEffect(() => {
        Router.events.on('routeChangeComplete', onRouteChangeComplete);

        return () => {
            Router.events.off('routeChangeComplete', onRouteChangeComplete);
        };
    }, []);
}

const useAnalyticsTracking = () => {
    useTrackSignedInUser();
    useTrackScreenViews();
};

export default useAnalyticsTracking;
